<template>
  <div class="flex-grow user-roles-box flex flex-wrap justify-end">
    <template v-for="(user, index) in orderedUsers" :key="index">
      <Popper hover arrow placement="bottom">
        <div
            class="role-item text-center mx-[10px]"
            @click="changeUser(user.roleType)"
        >
          <div
              class="relative user-avatar cursor-pointer"
              :class="{'current-user-avatar': (currentUser && user.id===currentUser.id)}"
          >
            <img
                v-show="currentUser && user.id===currentUser.id"
                :src="user.avatarHovered"
                :key="user.avatarHovered"
                class="object-contain max-h-full max-w-full"
            />
            <img
                v-show="currentUser && user.id!==currentUser.id"
                :src="user.avatar"
                :key="user.avatar"
                class="object-contain max-h-full max-w-full"
            />
          </div>
        </div>
        <template #content="props">
          <slot name="content" v-bind="props">
            <div style="max-width: 270px">
              <h4 class="uppercase text-red text-xs">Coin account number</h4>
              {{ getShortenString(user.accountNumVal) }}
              <i class="icon-content_copy ml-2.5" :class="{'copied': bufferText===user.accountNumVal}" @click="copyToBuffer(user.accountNumVal)"></i>
            </div>
          </slot>
        </template>
      </Popper>
      <span v-show="user.progressDots.length>0" v-for="(dot, index) in user.progressDots" :key="index" class="flex justify-center items-center relative z-50 overflow-hidden"
            style="min-width: 25px; max-width: 25px; overflow: hidden"
      >
            <span class="dot mx-[8px]" :class="{'dot-2x': (currentUser && user.id===currentUser.id && isRelatedToCurrentStep(dot))}"></span>
          </span>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import router from "@/router";
import string from "@/helpers/string";
import Popper from "vue3-popper";

export default {
  name: "HeaderLeftComponentHome",
  components: {
    Popper
  },
  computed: {
    ...mapGetters({
      sessionId: 'getSessionId',
      currentUser: 'getCurrentUser',
      users: 'getUsers',
      currentStep: 'getCurrentStep',
    }),
    orderedUsers() {
      const users = [...this.users];
      users.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      })
      return users
    },
  },
  data: () => ({
    bufferText: "",
    showModal: false,
  }),
  methods: {
    changeUser(nextRoleType) {
      if (nextRoleType === this.currentUser.roleType) return;

      this.$store.commit('setSelectedNft', null)
      this.$store.commit('setCurrentUser', null)
      this.$store.commit('setStepsState', null)
      this.$store.commit('setCurrentStep', null)
      this.$store.commit('setStoreCurrentStep', null)
      router.push({ path: `/users/${nextRoleType}` })
    },
    async copyToBuffer(str) {
      string.copyToClipboard(str);
      this.bufferText = await navigator.clipboard.readText()
    },
    isRelatedToCurrentStep(dot) {
      const isRelated = dot.areStepsDone.find(s => s === this.currentStep);
      return isRelated;
    },
    getShortenString(str) {
      return string.shortenText(str)
    },
  }
}
</script>

<style scoped>

</style>
