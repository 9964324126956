import {ConditionalStep} from "@/models/conditionalStep";
import {Step} from "@/models/step";
import {NftType} from "@/utils/NftType";
import {randomNumber} from "@/helpers/number";
import {User} from "@/models/user";
import {setToStorage} from "@/helpers/sessionStorage";

export function setupSessionId() {
    const storedSesId = sessionStorage.getItem('sessionId');
    let sesId = storedSesId !== null ? parseInt(storedSesId) : null;

    if (sesId == null) {
        sesId = randomNumber(1000, 9999);
        setToStorage('sessionId', sesId)
    }
    return sesId;
}

export function shuffleArray(array: Array<any>) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
}

export function preselectNfts(type: NftType, txCount: number, txArr: Array<any>) {
    txArr = shuffleArray(txArr)
    let randomTxArr: Array<any> = []
    txArr.sort( () => 0.5 - Math.random());
    txArr.forEach((tx: any) => {
        if ((tx.type === type) && (randomTxArr.length < txCount)) {
            randomTxArr = [...randomTxArr, tx]
        }
    })

    return randomTxArr
}
export function preselectNftsNoShuffle(type: NftType, txCount: number, txArr: Array<any>) {
    let selectedTxArr: Array<any> = []
    txArr.forEach((tx: any) => {
        if ((tx.type === type) && (selectedTxArr.length < txCount)) {
            selectedTxArr = [...selectedTxArr, tx]
        }
    })

    return selectedTxArr
}
export function preselectNotaries(type: string, userId: string, txCount: number, txArr: Array<any>) {
    txArr = shuffleArray(txArr)
    let randomTxArr: Array<any> = []
    txArr.sort( () => 0.5 - Math.random());
    txArr.forEach((tx: any) => {
        if ((tx.type === type) && (randomTxArr.length < txCount) && tx.userId === userId) {
            randomTxArr = [...randomTxArr, tx]
        }
    })

    return randomTxArr
}

export function getNextActor(actors: Array<User>, actorId: string) {
    const index = actors.findIndex((actor) => actor.id === actorId)
    if (index === -1 || index === actors.length - 1) {
        return null
    }

    return actors[index + 1]
}


export function getNextStep(stepsState: Array<Step | ConditionalStep>, i: number) {
    return stepsState[i + 1] ? stepsState[i + 1] : null;
}

export function getPreviousStep(stepsState: Array<Step | ConditionalStep>, i: number) {
    return stepsState[i - 1] ? stepsState[i - 1] : null;
}

export function findUserAccNumValById(id: string, usersArr: Array<User>) {
    const user = usersArr.find(u => u.id===id);
    return user ? user.accountNumVal : null;
}

export function findUserNameByAccNumVal(accNumVal: string, usersArr: Array<User>) {
    const user = usersArr.find(u => u.accountNumVal===accNumVal);
    return user ? user.roleType : null;
}
