<template>
  <Header layout="user" />
  <main>
    <slot />
  </main>
  <Footer />
</template>

<script>
import Header from "@/components/header/HeaderComponent";
import Footer from '../../components/FooterComponent'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
