<template>
  <nav class="flex-grow w-auto text-blue-dark font-jost-medium text-[22px] flex items-center flex-wrap gap-[15%]">
    <div>
      <img src="../../../public/img/geeq-logo.svg" class="w-[159px] h-[58px] cursor-pointer" @click="navigateToHome()" />
    </div>
    <div class="flex flex-grow gap-1.5">
          <span class="font-jost-medium text-[22px]">
            Your session ID:
          </span>
      <span class="text-blue-light font-jost-medium text-[22px]">
            #{{ sessionId }}
          </span>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  emits: ["navigateHome"],
  name: "HeaderLeftComponentHome",
  computed: {
    ...mapGetters({
      sessionId: 'getSessionId',
    }),
  },
  methods: {
    navigateToHome() {
      this.$emit('navigateHome')
    }
  }
}
</script>

<style scoped>

</style>
