import axios from 'axios';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: "http://localhost:4000" });

export function setHeader(key: string, value: string) {
    http.defaults.headers.common[key] = value
}

export default {
    getData() {
        return http.get('/api/nft')
    },
};
