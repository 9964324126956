<template>
  <main class="block !bg-[#F8F8F8F0]">
    <slot />
    <Footer />
  </main>
</template>

<script>
import Footer from '../../components/FooterComponent'
export default {
  components: {
    Footer
  }
}
</script>
<style>
/**/
</style>
