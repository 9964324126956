import {createStore} from 'vuex';
import {User} from '@/models/user';
import {Nft} from '@/models/nft';
import {Role} from "@/models/role";
import api from "@/helpers/api";
import {boolean} from "yargs";

// define your typings for the store state
export interface State {
  isDataGotten: boolean,
  version: string | null,
  chain: number | null,
  chainId: number | null,
  initialBlock: number | null,
  sessionId: number | null,
  users: Array<User>,
  roles: Array<Role>,
  currentUser: User | null,
  selectedNft: Nft | null,
  stepsState: Record<string, unknown> | null,
  currentStep: string | null,
  storeCurrentStep: string | null,
  actionBoxItemIndex: number | null,
  actionBoxItemId: string | null,
  cropTxCount: number | null,
  shipmentTxCount: number | null,
  welcomePageContent: any,
  notaryTxArray: any,
  nftTxDataArray: any,
  txHistoryArray: any,
}

export default createStore<State>({
  state: {
    isDataGotten: false,
    version: null,
    chain: null,
    chainId: null,
    initialBlock: null,
    sessionId: null,
    users: [],
    roles: [],
    currentUser: null,
    selectedNft: null,
    stepsState: null,
    currentStep: null,
    storeCurrentStep: null,
    actionBoxItemIndex: null,
    actionBoxItemId: null,
    cropTxCount: null,
    shipmentTxCount: null,
    welcomePageContent: null,
    notaryTxArray: [],
    nftTxDataArray: [],
    txHistoryArray: [],
  },
  getters: {
    getVersion: (state) => state.version,
    getIsDataGotten: (state) => state.isDataGotten,
    getChain: (state) => state.chain,
    getChainId: (state) => state.chainId,
    getInitialBlock: (state) => state.initialBlock,
    getSessionId: (state) => state.sessionId,
    getCurrentUser: (state) => state.currentUser,
    getSelectedNft: (state) => state.selectedNft,
    getUsers: (state) => state.users,
    getRoles: (state) => state.roles,
    getStepsState: (state) => state.stepsState,
    getCurrentStep: (state) => state.currentStep,
    getStoreCurrentStep: (state) => state.storeCurrentStep,
    getActionBoxItemId: (state) => state.actionBoxItemId,
    getWelcomePageContent: (state) => state.welcomePageContent,
    getCropTxCount: (state) => state.cropTxCount,
    getShipmentTxCount: (state) => state.shipmentTxCount,
    getNotaryTxArray: (state) => state.notaryTxArray,
    getNftTxDataArray: (state) => state.nftTxDataArray,
    getTxHistoryArray: (state) => state.txHistoryArray,
  },
  mutations: {
    setIsDataGotten(state, isGotten) {
      state.isDataGotten = isGotten;
    },
    setVersion(state, version) {
      state.version = version;
    },
    setChain(state, chain) {
      state.chain = chain;
    },
    setChainId(state, chainId) {
      state.chainId = chainId;
    },
    setInitialBlock(state, initialBlock) {
      state.initialBlock = initialBlock;
    },
    setSessionId(state, id) {
      state.sessionId = id;
    },
    setCurrentUser(state, user) {
      state.currentUser = {...user}
    },
    setUsers(state, users) {
      state.users = [...users]
    },
    setRoles(state, roles) {
      state.roles = [...roles]
    },
    setSelectedNft(state, nft) {
      state.selectedNft = {...nft}
    },
    setStepsState(state, stepsState) {
      state.stepsState = {...stepsState}
    },
    setActionBoxItemId(state, id) {
      state.actionBoxItemId = id
    },
    setCurrentStep(state, currentStep) {
        state.currentStep = currentStep;
    },
    setWelcomePageContent(state, content) {
        state.welcomePageContent = content;
    },
    setCropTxCount(state, count) {
        state.cropTxCount = count;
    },
    setShipmentTxCount(state, count) {
        state.shipmentTxCount = count;
    },
    setNotaryTxArray(state, notaryTxArr) {
        state.notaryTxArray = notaryTxArr;
    },
    setNftTxDataArray(state, nftTxArr) {
        state.nftTxDataArray = nftTxArr;
    },
    setTxHistoryArray(state, txHistory) {
        state.txHistoryArray = txHistory;
    },
    setStoreCurrentStep(state, currentStep) {
      if (state.storeCurrentStep !== currentStep) {
        state.storeCurrentStep = currentStep;
        state.actionBoxItemId = null;
      }
    },
  },
  actions: {
    getDemoData() {
      api.getData()
          .then(async (res) => {
            const data = res.data.result;
            await this.commit('setVersion', data.version);
            await this.commit('setChain', data.chain);
            await this.commit('setChainId', data.chainId);
            await this.commit('setInitialBlock', data.initialBlock);
            await this.commit('setUsers', data.users);
            await this.commit('setRoles', data.roles);
            await this.commit('setCropTxCount', data.preselectedCropTxCount);
            await this.commit('setShipmentTxCount', data.preselectedShipmentTxCount);
            await this.commit('setNotaryTxArray', data.predefinedNotariesArray);
            await this.commit('setNftTxDataArray', data.predefinedNftsArray);
            await this.commit('setTxHistoryArray', data.transactionsHistory);
            await this.commit('setWelcomePageContent', data.welcomePageContent);
            this.commit('setIsDataGotten', true);
          })
    }
  }
})
