export default {
  copyToClipboard(hash: string) {
    navigator.clipboard.writeText(hash)
  },
  shortenText(str: string) {
    if (str && str.length > 20) {
      return str.substr(0, 10) + '...' + str.substr(str.length-10, str.length);
    }
    return str;
  },

  // Function to generate random UId
  getMessageUid() {
    const a = new Uint32Array(3);
    window.crypto.getRandomValues(a);
    return (performance.now().toString(36)+Array.from(a).map(A => A.toString(36)).join("")).replace(/\./g,"");
  },
  getPartiallyBoldedString(title: string) {
    const subString = title.substring(
        title.indexOf("{") + 1,
        title.lastIndexOf("}")
    );
    const subStringModified = `<span class="font-jost-semibold">${subString}</span>`
    const modifiedTitle = title.replace(`{${subString}}`, subStringModified);

    return modifiedTitle;
  },
  rtrim(str: string) {
    if(!str) return str;
    return str.replace(/\s+$/g, '');
  },
  splitWithGivenChar(string: string, char: RegExp) {
    let arr: Array<string> = [];
    if (string.length > 0) {
      arr = string.split(char)
    }
    return arr;
  }
}
