<template>
  <component :is="layout">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import HomeLayout from "@/views/Layouts/HomeLayout";
import UserLayout from "@/views/Layouts/UserLayout";
import GuestLayout from "@/views/Layouts/GuestLayout";

export default {
  name: 'layout-wrapper',
  components: {
    HomeLayout,
    UserLayout,
    GuestLayout,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data() {
    return {
      initial: true,
      window: {
        width: 0
      }
    }
  },
  computed: {
    route() {
      return this.$route;
    },
    layout: function() {
      return `${this.route.meta.layout}-layout`
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    }
  }
}
</script>

<style lang="css"></style>
