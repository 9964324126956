<template>
  <nav class="flex-grow w-auto text-blue-dark font-jost-medium text-[22px] flex items-center">
    <img src="../../../public/img/geeq-logo.svg" class="w-[159px] h-[58px] cursor-pointer" />
  </nav>
</template>

<script>
export default {
  emits: ["navigateHome"],
  name: "HeaderLeftComponentHome",
}
</script>

