import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Wrapper from '@/views/Layouts/LayoutWrapper.vue'
import store from '../store'

function load(path: string) {
  // '@' is aliased to src/components
  return () => import(`@/views/${path}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'home',
        component: load('HomeView'),
        meta: {
          protected: true,
          layout: 'home'
        },
        beforeEnter: (to, from, next) => {
          // empty storage to reset simulation
          sessionStorage.clear()
          store.commit('setSelectedNft', null)
          store.commit('setCurrentUser', null)
          store.commit('setStepsState', null)
          store.commit('setCurrentStep', null)
          store.commit('setStoreCurrentStep', null)
          next()
        }
      },
      {
        path: '/users/:type/:step?',
        name: 'users',
        component: load('UserView'),
        meta: {
          protected: true,
          layout: 'user'
        },
      },
      {
        path: '/transaction-details/:uid',
        name: 'transactionDetails',
        component: load('TransactionDetailsView'),
        meta: {
          protected: true,
          layout: 'guest'
        }
      },
      {
        path: '/pdf-test',
        name: 'pdfTest',
        component: load('PdfTest'),
        meta: {
          protected: true,
          layout: 'user'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: load('PageNotFoundView'),
        meta: {
          notFound: true,
          protected: false,
          layout: 'guest'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // return desired position
    return { top: 0 }
  }
})
export default router




