<template>
  <Modal :showModal="showModal" @close="showModal=false">
    <template #content="{navigateToHome, close}">
      <div class="modal font-jost-regular px-[33px] pt-[51px] pb-[31px] 5xl:px-[73px] 5xl:pt-[81px] 5xl:pb-[41px] max-w-[1000px] 5xl:max-w-[1118px] !border-2 !border-[#2E4064]"
           role="dialog"
           v-if="showModal"
      >
        <h1 class="font-jost-semibold text-blue-light title text-[27px] mb-7">Are you sure?</h1>
        <p class="text-[27px] text-[#444444] pr-2.5">Going back to the home page will reset your progress. If you just want to change agents, click on the desired one in the top right corner.</p>
        <div class="flex justify-end gap-5 mt-10">
          <button @click="navigateToHome" class="action-btn !text-[22px] font-jost-medium uppercase !py-[12px] !px-[18px]">Start over</button>
          <button @click="close()" class="action-btn !text-[22px] font-jost-medium uppercase py-[10px]">Keep going</button>
        </div>
      </div>
    </template>
  </Modal>

  <header class="bg-white header py-[30px] 4xl:py-[36px] short:py-[25px] 2xl:px-[159px] px-[60px]">
    <div class="container flex mx-auto items-center">
      <HeaderLeftComponentUser
          @navigateHome="navigateToHome"
          v-if="layout==='user'"
          :sessionId="sessionId"
      />
      <HeaderLeftComponentHome
          @navigateHome="navigateToHome"
          v-if="layout==='home'"
      />
      <HeaderRightComponent />
    </div>
  </header>
</template>
<script>
import {mapGetters} from "vuex";
import Modal from "@/components/ModalComponent";
import {setupSessionId} from "@/helpers/common";
import HeaderLeftComponentHome from "@/components/header/HeaderLeftComponentHomeLayout";
import HeaderLeftComponentUser from "@/components/header/HeaderLeftComponentUserLayout";
import HeaderRightComponent from "@/components/header/HeaderRightComponent";

export default {
  name: 'HeaderComponent',
  components: {
    HeaderLeftComponentUser,
    HeaderLeftComponentHome,
    HeaderRightComponent,
    Modal,
  },
  props: {
    layout: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
  }),
  computed: {
    ...mapGetters({
      sessionId: 'getSessionId',
    }),
  },
  watch: {
    currentStep() {
      this.$forceUpdate;
    }
  },
  mounted() {
    const id = setupSessionId();
    this.$store.commit('setSessionId', id);
  },
  methods: {
    navigateToHome() {
      if (this.$route.name !== 'home') {
        this.showModal = true
      }
    }
  }
}
</script>
<style>
/*.header {*/
/*  border: 1px solid lightgray;*/
/*}*/
.role-item {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom {
  position: absolute;
  top: 40%;
  left: 45%;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vh;
  height: 100vh;
  background: red;
}
.dot {
  height: 9px;
  width: 9px;
  background-color: #E6E7E8;
  border-radius: 50%;
  display: inline-block;
  /*margin: 0 2px;*/
}
.dot-2x {
  transform: scale(2);
}
.user-avatar::before {
  content: " ";
  width: 80px;
  height: 80px;
  background-image: none;
  position:absolute;
  top: 0;
  left: 0;
}
.user-avatar.current-user-avatar::before {
  content: " ";
  width: 80px;
  height: 80px;
  background-image: url('../../../public/img/circle1.svg');
  position:absolute;
  top: 0;
  left: 0;
}

</style>
<style scoped>
.action-btn {
  font-size: 16px;
  padding: 14px 25px;
}
</style>
