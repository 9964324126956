<template>
  <transition name="fade" appear>
    <div class="modal-overlay"
         v-if="showModal"
         @click="close()"></div>
  </transition>
  <transition name="pop" appear>
    <slot name="content" :navigateToHome="navigateToHome" :close="close"></slot>
  </transition>
</template>

<script>
export default {
  name: "ModalComponent",
  emits: ["close"],
  props: {
    showModal: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    navigateToHome() {
      this.$router.push({ path: "/" })
    }
  }
}
</script>

<style scoped>
.title {
  font-family: 'Jost-SemiBold', sans-serif;
}
.button {
  border: 1px solid #444444;
  color: #444444;
  background: #fff;
  appearance: none;
  font: inherit;
  font-size: 22px;
  padding: 5px 15px;
  /*border-radius: 29px;*/
  cursor: pointer;
}

.modal {
  /*position: absolute;*/
  position: fixed;
  border: 1px solid #707070D5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: left;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: #FFF;
  z-index: 999;
  transform: none;
}
.modal h1 {
  /*margin: 0 0 1rem;*/
}

.modal-overlay {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #707070D5;
  opacity: 0.3;
  cursor: pointer;
}

/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
